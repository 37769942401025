export const TEXT = "TEXT";
export const EMAIL = "EMAIL";
export const SWITCH = "SWITCH";
export const COUNTER = "COUNTER";
export const SELECT = "SELECT";
export const RADIO_BUTTON = "RADIO_BUTTON";
export const MULTI_SELECT = "MULTI_SELECT";
export const TEXT_AREA = "TEXT_AREA";
export const FILE_TYPE = "FILE_TYPE";
export const IMAGE = "IMAGE";
export const VIDEO = "VIDEO";
export const DATE_TIME_PICKER = "DATE_TIME_PICKER";
export const TEXT_EDITOR = "TEXT_EDITOR";
export const SAVE = "SAVE";
export const SAVE_AND_CLOSE = "SAVE_AND_CLOSE";
export const SAVE_AND_NEW = "SAVE_AND_NEW";
export const SAVE_AND_NEXT = "SAVE_AND_NEXT";
export const DIVIDER = "DIVIDER";
export const ERROR = "ERROR";
export const SUCCESS = "SUCCESS";
export const TEXT_BUTTON = "TEXT_BUTTON";
export const LABEL = "LABEL";
export const LABEL_PARTATION = "LABEL_PARTATION";
export const BUTTON = "BUTTON";
export const COLOR_PICKER = "COLOR_PICKER";
export const WARNING = "WARNING";

// Tab names
export const TAB_TABS = "Tabs";
export const TAB_ROLES = "Roles";
export const TAB_USERS = "Users";
export const TAB_EVENT_TYPES = "Event Types";
export const TAB_PLAYERS = "Players";
export const TAB_PLAYER_HISTORY = "Player History";
export const TAB_EVENT_HISTORY = "Event History";
export const TAB_PLAYER_EVENT_HISTORY = "Player Event History";
export const TAB_MARKET_TYPE = "Market Type List";
export const TAB_TEAMS = "Teams";
export const TAB_SOCIAL_MEDIA = "Social Media";
export const TAB_AWARDS = "Awards";
export const TAB_MATCH_TYPE = "Match Types";
export const TAB_PANELTY_RUNS = "Penalty Runs";
export const TAB_COMMENTARY = "Commentary";
export const TAB_COMMENTARY_HISTORY = "Commentary History";
export const TAB_COMPETITION = "Competition";
export const TAB_TOURNAMENT = "Tournament Team Points";
export const TAB_EVENT = "Events";
export const TAB_CONFIG = "Config";
export const TAB_BLOCKS = "Blocks";
export const TAB_PAGE_FORMAT = "Page Format";
export const TAB_PAGE = "Page";
export const TAB_AUTO_EVENT = "Auto Event";
export const TAB_MANUAL_EVENT = "Manual Event";
export const Tab_Menu_List = "Menu List";
export const TAB_NEWS = "News";
export const TAB_SHOT_TYPE = "Shot Type";
export const TAB_PHOTOLIBRARY = "Photo Library";
export const TAB_VIDEOLIBRARY = "Video Library";
export const TAB_PHOTOS = "Photos";
export const TAB_BANNER = "Banner";
export const TAB_SUBSCRIBERS = "Subscribers";
export const TAB_MARKET_TEMPLATE = "Market Templates";
export const TAB_MARKET_TEMPLATE_RUNNER = "Market Template Runner";
export const TAB_EVENT_MARKETS = "Event Markets";
export const TAB_SET_MARKETS_RESULT = "Market Result";
export const TAB_SET_SESSION_RESULT = "Session Result";
export const TAB_VENDOR = "Vendors";
export const TAB_DISPLAYSTATUS = "DisplayStatus";
export const TAB_ClientSocket = "ClientSocket";
export const TAB_API = "API";
export const TAB_API_ENDPOINTS = "API Endpoints";
export const TAB_NOTIFICATION = "Notification";
export const TAB_TEMPLATE = "Template";
export const TAB_CLIENT = "Client";
export const TAB_MAIL_SETTINGS = "MailSettings";
export const TAB_COMMENTARY_LOGS = "CommentaryLogs";
export const TAB_THIRDPARTY_LOGS = "ThirdpartyLogs";
export const TAB_ERROR_LOGS = "ErrorLogs";
export const TAB_PREDICTOR_LOGS = "PredictorLogs";
export const TAB_EVENT_RESULT = "EventResult";
export const TAB_UNDO_LOGS = "UndoLogs";
export const TAB_SCORING_LOGS = "ScoringLogs";

// Permission Type
export const PERMISSION_ADD = "isAdd";
export const PERMISSION_EDIT = "isEdit";
export const PERMISSION_DELETE = "isDelete";
export const PERMISSION_VIEW = "isView";
export const PERMISSION_SUSPEND = "isSuspend";

export const LOGOUT = "/logout";
export const REMEMBER_ME_KEY = "rememberMe";
export const USER_DATA_KEY = "userData";

// Commentary
export const COMMENTARY_TOSS_SCREEN = "COMMENTARY_TOSS_SCREEN";
export const COMMENTARY_PLAYER_SELECTION_SCREEN =
  "COMMENTARY_PLAYER_SELECTION_SCREEN";
export const COMMENTARY_MAIN_SCREEN = "COMMENTARY_MAIN_SCREEN";
// export const COMMENTARY_TOSS = "COMMENTARY_TOSS"

export const BATTING_STATUS = 1;
export const BOWLING_STATUS = 2;

export const STRING_SEPERATOR = "_##_";
export const CONTENT_IMAGE_TYPE = {
  BLOCKS: "Blocks"
}
export const OPEN_MARKET_CONNECT = "connectEventMarket";
export const OPEN_MARKET_DATA = "updateMarketData";
export const UNDO_CALLED = "undoCalled";
export const MARKET_RUNNER_CONNECT = "marketRunnerConnection";
export const MARKET_RUNNER_DATA = "marketRunners";
export const CONNECT_COMMENTARY = "conCommentary";
export const BET_ALLOW = "betAllow";
export const COMMENTARY_UPDATE = "comUpdate";
export const UPDATE_BALL_STATUS = "updateBallStatus";
export const COMMENTARY_STATUS_OPEN = 1;
export const COMMENTARY_STATUS_TOSS_DONE = 2;
export const COMMENTARY_STATUS_IN_PROGRESS = 3;
export const COMMENTARY_STATUS_END = 4;
